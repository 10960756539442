<template lang="pug">
    .link
        router-link(:to="link", v-if="!external")
            slot
        a(:href="link", v-else-if="link", target="_blank", rel="noopener noreferrer")
            slot
        span(v-else)
            slot
</template>

<script>
export default {
    name: "component-link",
    props: {
        contents: {
            type: [ Object, Array ],
            required: true
        },
    },
    computed: {
        external() {
            return this.contents.external
        },
        link() {
            return this.contents.link
        }
    }
}
</script>

<style lang="stylus" scoped src="./Link.styl"></style>