<template lang="pug">
.empreendimento
    Link(:contents="contents.link")
        .img
            img(:src="contents.image")
        .description
            .name
                h3 {{contents.title}}
                hr
                ul
                    li(v-for="diferencial in contents.diferenciais")
                        SvgIcon(data="@svgs/tick.svg", original)
                        span(v-html="diferencial")
            .endereco
                span(v-if="contents.endereco")
                    FontAwesomeIcon(:icon="icons.faLocationDot").icon
                    | {{contents.endereco}}
                SvgIcon(:data="contents.logo", original).logo
</template>

<script>
import Link from '@components/Link/Link.vue';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'component-item-empreendimento',
  components: {
    Link
  },
  props: {
    contents: {
      type: [Object, Array],
      required: true
    }
  },
  data() {
    return {
      icons: {
        faLocationDot
      }
    }
  }
}
</script>

<style lang="stylus" scoped src="./ItemEmpreendimento.styl"></style>
